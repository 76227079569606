@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.client-logo {
  @include container();
  text-align: center;
  margin-bottom: $p-xl;

  @include media("above-xs") {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &--no-link {
    
  }

  &__image {
    img {
      max-width: 200px;
    }

    @include media("below-xs") {
      margin-bottom: $p-md;
    }
  }
}
