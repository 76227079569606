@import '../../base/settings.scss';
@import '../../base/media-queries.scss';
@import '../../base/mixins.scss';

.case-study-card {
  margin-bottom: $p-sm;
  position: relative;
  text-align: center;

  @include media('above-xs') {
    width: 50%;
  }

  &__media-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 550px;
    z-index: 1;
  }

  &__media {
    position: absolute;
    width: 100%;
    height: 110%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__media-content {
    padding: 20px 37px 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), $black);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    h3 {
      color: $white;
    }
    @include media('above-xs') {
      padding-top: 35px;
    }
  }

  &__heading {
    @include h3();
    margin-bottom: $p-sm;
    text-align: center;

    a {
      color: $white;
      text-decoration: none;
    }
  }

  &__image {
    margin-bottom: $p-xs;
  }

  &__intro {
    color: $white;
    font-size: 14px;
    margin-bottom: $mb-p-xs;

    @include media('above-xs') {
      font-size: 14px;
    }
  }

  &__summary {
    font-size: 18px;
    margin-bottom: $p-sm;

    @include media('above-xs') {
      font-size: 14px;
    }
  }

  &__content-wrap {
    position: relative;
    z-index: 1;
    padding: 25px 10px 45px;
    @include media('above-xs') {
      padding: 30px 0 35px 0;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  /*
  &-triangle {
    &--top-left {
      @include bgTriangle($white, top-left);
    }
    &--top-right {
      @include bgTriangle($white, top-right);
    }
    &--bottom-left {
      @include bgTriangle($white, bottom-left);
    }
    &--bottom-right {
      @include bgTriangle($white, bottom-right);
    }
  }
  */
}
