@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.case-study-pair {
  margin-bottom: $p-md;

  @include media("above-xs") {
    display: flex;
  }
}
