@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.service-intro {

  &__heading {
    margin-bottom: $p-xs;

    a {
      color: $black;
      text-decoration: none;
      transition: color 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:hover,
      &:focus {
        color: $red;
      }
    }

    @include media("below-xs") {
      text-align: center;
    }
  }

  &__item {
    @include media("above-xs") {
      display: flex;
    }

    @include media("below-xs") {
      text-align: center;
    }

    + .service-intro__item {
      margin-top: $p-md;
    }
  }

  &__icon {
    @include media("above-xs") {
      flex: 1 0 74px;
      margin-right: $p-md;
      height: 74px; // for IE11
    }

    @include media("below-xs") {
      margin: 0 auto 10px;
      width: 74px;
      height: 74px; // for IE11
    }

    img {
      box-shadow: 2.5px 2.5px 0 0 rgba(3, 4, 3, 0.1);
    }
  }

  &__text {
    font-size: 14px;
    width: 100%;
  }

  p {
    margin-bottom: $p-xs;
  }
}
