@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

$icon-size: 74px;

.form-card {
  /*
  @include bgTriangle(#f8f8f6, top-right);
  */
  background-color: $light-green;
  padding: $p-xl $p-md $p-md;
  position: relative;
  margin-top: $p-xl;
  margin-bottom: $p-xl;

  @include media("above-xs") {
    @include container();
    padding: $p-xl $p-xl $p-md;
    margin-top: $p-giant;
  }

  &__icon,
  &__date {
    box-shadow: 2.5px 2.5px 0 0 rgba(3, 4, 3, 0.1);
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    top: calc(-#{$p-xl});
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
  }

  &__date {
    @include headingFont();
    background-color: $white;
    display: inline-block;
    font-size: 14px;
    padding: $p-xs $p-sm;
    text-align: center;
    text-transform: uppercase;
  }

  &__day {
    @include stripe($red, auto, auto);
    display: block;
    font-size: 34px;
    line-height: 1;
    margin-top: -5px;
  }

  &__inner {
    position: relative;
    z-index: 1;

    @include media("above-xs") {
      @include container(380px);
    }
  }

  &__contact {
    @include unstyledList();
    @include headingFont();
    display: flex;
    justify-content: center;
    font-size: 17px;
    margin-bottom: $p-sm;

    @include media("above-xs") {
      > li {
        margin: 0 $p-xs;
      }
    }

    @include media("below-xs") {
      flex-direction: column;
      align-items: center;
    }

    a {
      @include bodyLink($black, $red, $font-heading);
    }
  }

  &__text {
    padding-bottom: 0;

    a {
      @include bodyLink($black, $red, $font-body-bold);
    }
  }

  &__heading,
  &__text,
  &__small {
    text-align: center;
  }

  &__small {
    color: $mid-grey;
    font-size: 12px;
    line-height: 1.5;
    position: relative;
    z-index: 1;
  }

  &__button {
    margin-top: $p-xs;
    text-align: center;
    width: 100%;
  }
}
