@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.highlights-card {
  @include bgTriangle(#f8f8f6, top-right);
  background-color: $white;
  margin-bottom: $p-lg;
  padding: $p-lg $p-xl;
  position: relative;
  text-align: center;
  
  @include media("above-xs") {
    @include container();
  }

  &__body {
    position: relative;
    z-index: 1;
  }

  &__heading {
    margin-bottom: $p-md;
    text-align: center;
  }

  &__item {
    @include bodyText($mid-grey);
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include media("above-xs") {
      max-width: 320px;
    }

    &:not(:last-child) {
      margin-bottom: calc(#{$p-sm} * 1.33);
    }

    &-heading {
      margin-bottom: calc(#{$p-xs} / 2);
      text-align: center;
    }

    &-icon {
      margin-bottom: $p-xs;
      width: 74px;
    }
  }

  p {
    margin-bottom: 0;
  }
}
