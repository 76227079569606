@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.grid-guide {
  @include container(1440px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  pointer-events: none;

  &--off {
    display: none;
  }

  &--top {
    z-index: 1;
    opacity: 0.3;
  }

  &--bottom {
    z-index: -1;
  }

  @media screen and (min-width: $b-sm) {
    &__row {
      height: 100%;
      display: flex;
    }

    &__col {
      @include cols(1);
      background: red;
      background-clip: content-box;
    }
  }
}
