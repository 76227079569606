//  Media queries
// ------------------------------------------------------------------
//  Usage:
/*
  @include media("md-up") {
    [styles for tablets and larger]
  }
  @include media("sm-down") {
    [styles for portrait tablets and smaller]
  }
*/

// above-xs
  // below-xs

$b-xs: 500px; // phone
$b-sm: 768px; // portrait tablet
$b-md: 1024px; // tablet
$b-lg: 1440px; // desktop
$b-xl: 1750px;// large desktop

// @todo: clear up min/max media query naming. confusing atm.
// e.g. phone-down, above-phone

// -----------------------------------------------
@mixin media($breakpoint) {

  $above-xs: $b-xs + 1;
  $above-sm: $b-sm + 1;
  $above-md: $b-md + 1;
  $above-lg: $b-lg + 1;
  $above-xl: $b-xl + 1;

  // Min width
  @if $breakpoint == "$above-xl" {
    @media only screen and (min-width: $above-xl) { // 1751px
      @content;
    }
  } @else if $breakpoint == "above-lg" {
    @media only screen and (min-width: $above-lg) { // 1441px
      @content;
    }
  } @else if $breakpoint == "above-md" {
    @media only screen and (min-width: $above-md) { // 1025px
      @content;
    }
  } @else if $breakpoint == "above-sm" {
    @media only screen and (min-width: $above-sm) { // 769px
      @content;
    }
  } @else if $breakpoint == "above-xs" {
    @media only screen and (min-width: $above-xs) { // 501px
      @content;
    }
  }
  // Max-width
  @else if $breakpoint == "below-xl" {
    @media only screen and (max-width: $b-xl) {
      @content;
    }
  } @else if $breakpoint == "below-lg" {
    @media only screen and (max-width: $b-lg) {
      @content;
    }
  } @else if $breakpoint == "below-md" {
    @media only screen and (max-width: $b-md) {
      @content;
    }
  } @else if $breakpoint == "below-sm" {
    @media only screen and (max-width: $b-sm) {
      @content;
    }
  } @else if $breakpoint == "below-xs" {
    @media only screen and (max-width: $b-xs) {
      @content;
    }
  } @else {
    @warn "#{$breakpoint} is not a valid breakpoint";
  }
}
