@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.embed form,
.embed body {
  background: transparent;
  overflow: hidden;
}

form.wufoo {
  position: relative;
  padding: inherit;
  overflow: hidden;
}

.wufoo {
  font-family: inherit;
  letter-spacing: inherit;
}

#form2 ul {
  list-style: none;
  padding-left: 0;
}

#form2 input,
#form2 textarea {
  background-color: #fff;
  border: none !important;
  box-shadow: inset 3px 3px 0 0 hsla(73, 6%, 54%, 0.4);
  padding: 9px 6px 6px 9px;
  width: 99%;
  transition: box-shadow 0.25s ease;
  font-size: 100%;
  line-height: 1.15;
}

#form2 textarea {
  height: 100px;
}

#form2 input:focus,
#form2 textarea:focus {
  outline: 0;
  box-shadow: inset 3px 3px 0 0 hsla(73, 6%, 54%, 0.9);
}

#form2 label {
  @include bodyText();
  font-size: 18px;
  font-weight: inherit;
  margin-bottom: 5px;
}

#form2 label span,
#header {
  display: none !important;
}

#form2 ul li {
  margin-bottom: 10px;
  padding: 0;
}

#form4 #saveForm {
  width:100%;
}

input#saveForm {
  width: 100%;
  @include btn($white, $red, $red);
  @include btnArrow($white, $white);
  background-size: 20px 9px;
  box-shadow: none;
  padding: 10px 24px;
  margin-top: 10px;
}

input#saveForm:hover,
input#saveForm:focus {
  @include btnHover($white, $green, $green);
  background-size: 20px 9px;
  padding: 10px 24px;
}

form.wufoo {
  position: relative;
  padding: inherit;
}

.wufoo {
  font-family: inherit;
  letter-spacing: inherit;
}
