@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.labs-card {
  background-color: $blue;
  margin-bottom: $p-sm;
  padding: $p-md $p-md $p-lg;
  position: relative;
  text-align: center;

  @include media("above-xs") {
    @include container();
  }

  &__icon {
    box-shadow: 2px 2px 0px 1px rgba(0,0,0,0.1);
    margin-bottom: $p-xs;
  }

  &__header {
    @include stripe($dark-blue, 150px, 150px);
    margin-bottom: $p-sm;
  }

  &__title {
    margin-bottom: $p-xs;
    text-align: center;
  }

  &__body {
    position: relative;
    z-index: 1;
  }

  &__btn {
    margin-top: $p-xs;
  }
}
