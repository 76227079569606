@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.team-card-list {
  @include container(760px);

  @include media("above-xs") {
    margin-top: $p-md;
    margin-bottom: $p-md;
  }

  .team-card {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s cubic-bezier($bez-transition), transform 0.8s cubic-bezier($bez-transition);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:not(:first-child) {
      margin-top: $p-md;

      @include media("above-xs") {
        margin-top: $p-sm;
      }
    }

    &:nth-child(2) {
      @include media("above-xs") {
        margin-top: $p-giant;
      }
    }
  }

  &.is-visible {
    .team-card {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
