.form {

  &__group {
    margin-bottom: $p-xs;

    &--half {
      @include media("above-xs") {
        display: flex;
        margin-left: calc(-#{$p-xs});
        margin-right: calc(-#{$p-xs});
      }

      div {
        @include media("above-xs") {
          margin: 0 $p-xs;
        }
      }
    }

    &--submit {
      margin-top: $p-sm;
    }
  }

  &__label {
    display: block;
    font-family: $font-body;
    font-size: 14px;
    margin-bottom: calc(#{$p-xs} / 2);
  }

  &__input {
    background-color: $white;
    border: solid 3px $light-grey;
    display: block;
    box-shadow: inset 3px 3px 0 0 rgba(142, 145, 131, 0.4);
    font-family: $font-body;
    padding: 9px 6px 6px 9px;
    width: 100%;
    transition: box-shadow 0.25s ease;

    &:focus {
      outline: 0;
      box-shadow: inset 3px 3px 0 0 rgba(142, 145, 131, 0.9);
    }
  }

  &__extra {
    font-size: 14px;
    text-align: center;
  }
}
