@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.header-cta-block {
  display: none;
  padding: $p-lg;

  @include media("above-xs") {
    display: block;
    width: 50%;
  }
}
