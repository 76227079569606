@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

$social-icon-size: 33px;

.team-card {
  background-color: $white;

  @include media("above-xs") {
    width: calc(50% - #{$p-xs});
  }

  &__body {
    padding: 0 0 $p-md;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__heading {
    @include h4();
    margin-bottom: $mb-p-xs;
  }

  &__role {
    color: $mid-grey;
    font-size: 14px;
  }

  p > a {
    @include bodyLink();
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-top: $p-sm;

    &-link {
      display: inline-block;
      text-indent: -9999px;
      margin: 0 calc(#{$p-xs} / 2);
      width: $social-icon-size;
      height: $social-icon-size;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-image 0.25s ease;
    }
  }

  &__twitter {
    @include socialIconLink('twitter', #cecece, $red);
  }

  &__medium {
    @include socialIconLink('medium', #cecece, $red);
  }

  &__linkedin {
    @include socialIconLink('linkedin', #cecece, $red);
  }
}
