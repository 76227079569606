.super-hero {
  @include container(960px);
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $p-xl;

  @include media("below-xs") {
    flex-direction: column;
  }

  > div {
    @include media("above-xs") {
      flex: 1 1 auto; // for IE11 (IE11 defaults for flex:1 are special)
    }
  }

  &__heading {
    @include media("above-xs") {
      text-align: left;
    }
  }

  &__text {
    @include media("above-xs") {
      max-width: 430px;
    }
    @include media("below-xs") {
      order: 2;
      text-align: center;
    }
  }

  &__icon {
    text-align: right;
    max-width: 50%;

    @include media("below-sm") {
      padding-left: $p-md;
    }

    @include media("below-xs") {
      margin-bottom: $p-xs;
      order: 1;
    }

    img {
      height: 100%; // default scaling for IE
    }
  }
}
