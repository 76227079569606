@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.location-block {
  @include container();
  margin-bottom: $p-md;
  
  @include media("above-xs") {
    display: flex;
    margin-bottom: $p-xl;
  }
}

.location {
  font-size: 14px;

  @include media("above-xs") {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  @include media("below-xs") {
    text-align: center;
  }

  &:first-child {
    
    @include media("above-xs") {
      border-right: solid 6px #cfc6cc;
      text-align: right;
    }

    @include media("below-xs") {
      border-bottom: solid 6px #cfc6cc;
    }

    .location__heading {
      @include media("above-xs") {
        text-align: right;
      }
    }

    .location__body {
      padding-top: 0;

      @include media("above-xs") {
        padding: $p-xs $p-lg $p-sm 0;
      }
    }
  }

  &__body {
    padding: $p-md 0 $p-xs;

    @include media("above-xs") {
      padding: $p-xs 0 $p-sm $p-lg;
    }
  }

  &__heading {
    margin-bottom: calc(#{$p-xs} / 2);

    @include media("below-xs") {
      text-align: center;
    }
  }

  &__map {
    margin: 0 
  }

  &__address {
    @include unstyledList();
  }

  &__cta-block {
    margin-top: auto;
    padding: $p-md 0;
    text-align: center;
  }
}