@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

@mixin parallaxCircle($size, $color) {
  width: $size;
  height: $size;
  background-color: $color;
  border-radius: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
}

@mixin parallaxTriangle($size, $color) {
  width: 0;
  height: 0; 
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
  position: absolute;
  -webkit-backface-visibility: hidden;
}

.parallax-contain {
  position: static;
}

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @include media("below-sm") {
    display: none;
  }

  &::before {
    content: ' ';
	  position: fixed;
  }
}

.parallax-1 {

  ::before {
    content: ' ';
    position: fixed;
  }

  .parallax__blue-circle {
    @include parallaxCircle(300px, $blue);
    right: -150px;
    top: 330px;
    z-index: -2;

    @include media("above-sm") {
      position: absolute;
    }
  }

  .parallax__red-circle {
    @include parallaxCircle(250px, $red);
    left: -125px;
    top: 650px;
  }

  .parallax__grey-circle {
    @include parallaxCircle(200px, $light-green);
    left: -100px;
    top: 62.5%;
  }

  .parallax__green-circle {
    @include parallaxCircle(240px, $green);
    left: -120px;
    top: 2450px;
  }

  .parallax__grey-triangle {
    @include parallaxTriangle(250px, $light-yellow);
    left: -250px;
    top: 0;
  }
}

body {
  overflow-x: hidden;
  position: relative;
}

.rellax {
  transition: transform 1.25s cubic-bezier(0.34, 0.5, 0.04, 0.99);
}
