@import '../../base/settings.scss';
@import '../../base/media-queries.scss';
@import '../../base/mixins.scss';

$social-icon-mobile-size: 40px;
$social-icon-size: 34px;

.footer {
  color: $white;
  overflow: hidden;

  &__container {
    @include container(960px);
    position: relative;
    z-index: 1;
  }

  &__header {
    margin-bottom: -95px;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  &__body {
    padding-top: 140px;
    padding-bottom: $p-md;
    background-color: $black;
    position: relative;

    /*
    @include bgTriangle($mid-grey);
    */
  }

  &__info {
    color: $white;
    margin-bottom: $p-lg;
    text-align: center;

    @include media('above-xs') {
      display: flex;
      justify-content: center;
      text-align: left;
    }

    > div {
      @include media('above-sm') {
        @include cols(4);
      }
    }

    &-heading {
      @include h4($white);

      @include media('below-xs') {
        text-align: center;
      }
    }

    &__left {
      @include media('above-xs') {
        margin-right: $p-md;
        text-align: right;
      }

      @include media('below-xs') {
        margin-bottom: $p-lg;
      }

      .footer__info-heading {
        @include media('above-xs') {
          text-align: right;
        }
      }
    }

    &__right {
      @include media('above-xs') {
        margin-left: $p-md;
      }
    }
  }

  &__btn {
    @include btn($white, transparent, $white);
    @include btnHover($mid-grey, $white, $white);
    @include btnArrow($white, $mid-grey);
  }

  &__social {
    display: flex;
    justify-content: space-around;
    margin-bottom: $p-md;

    @include media('above-xs') {
      justify-content: center;
    }

    &-link {
      display: inline-block;
      margin: 0 calc(#{$social-icon-size} / 2);
      text-indent: 9999px;
      width: $social-icon-mobile-size;
      height: $social-icon-mobile-size;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-image 0.25s ease;

      @include media('above-xs') {
        width: $social-icon-size;
        height: $social-icon-size;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__logo {
    margin-bottom: 35px;
  }

  &__twitter {
    @include socialIconLink('twitter', $dark-blue, $red);
  }

  &__facebook {
    @include socialIconLink('facebook', $dark-blue, $red);
  }

  &__medium {
    @include socialIconLink('medium', $dark-blue, $red);
  }

  &__linkedin {
    @include socialIconLink('linkedin', $dark-blue, $red);
  }

  // Sub footer / address

  &__sub {
    text-align: center;
  }

  &__address {
    @include smallText();
    @include unstyledList();
    color: $white;
    text-align: center;

    @include media('above-sm') {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    > li + li {
      margin: $mb-p-xs 0 0 0;

      @include media('above-sm') {
        margin: 0 0 0 $p-xs;
      }
    }
  }
}
