@charset "UTF-8";
/* @include media("md-up") { [styles for tablets and larger] } @include media("sm-down") { [styles for portrait tablets and smaller] } */
.text-block { color: #262626; font-family: "MaisonNeue-Book", Helvetica, Arial; font-size: 17px; line-height: 1.5; text-align: left; margin-left: auto; margin-right: auto; max-width: 600px; width: calc(100% - (20px * 2)); padding-bottom: calc(10px * 1.5); }

@media only screen and (min-width: 769px) { .text-block { font-size: 18px; } }

.text-block--center { text-align: center; }

.text-block p strong, .text-block ul strong, .text-block ol strong, .text-block label strong, .text-block p b, .text-block ul b, .text-block ol b, .text-block label b { font-family: "MaisonNeue-Bold", Helvetica, Arial; }

.text-block a:not([class^="btn"]) { color: #262626; font-family: "MaisonNeue-Bold", Helvetica, Arial; font-weight: normal; transition: text-decoration 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }

.text-block a:not([class^="btn"]):hover, .text-block a:not([class^="btn"]):focus { color: #262626; text-decoration-color: #ff0046; }

.text-block p { margin-bottom: 20px; }

.text-block ul { list-style: none; margin-bottom: 40px; }

.text-block ul li { position: relative; padding-left: 35px; }

.text-block ul li + li { margin-top: 10px; }

@media only screen and (min-width: 501px) { .text-block ul li { padding-left: 30px; } }

.text-block ul li::before { content: '•'; font-size: 40px; position: absolute; line-height: 17px; left: 0; top: 7px; }

@media only screen and (min-width: 501px) { .text-block ul li::before { font-size: 30px; line-height: 18px; top: 5px; } }

.text-block ol { list-style: none; margin-bottom: 40px; }

.text-block ol li { counter-increment: step-counter; margin-bottom: 10px; position: relative; padding-left: 35px; }

@media only screen and (min-width: 501px) { .text-block ol li { padding-left: 30px; } }

.text-block ol li::before { content: counter(step-counter) "."; font-family: "MaisonNeue-Bold", Helvetica, Arial; line-height: 1; position: absolute; left: 0; top: 3px; }

.text-block code { color: #404348; font-size: 15px; font-weight: bold; }

.text-block pre { border-top: solid 4px #BCE8EB; font-size: 13px; margin-bottom: 32px; }

.text-block .hljs-string, .text-block .hljs-meta-string { color: #00425E; }

.text-block .hljs-comment, .text-block .hljs-doctag { color: #00425E; }

.text-block .hljs-keyword, .text-block .hljs-literal, .text-block .hljs-symbol, .text-block .hljs-name { color: #00425E; }
