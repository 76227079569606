@import '../../base/settings.scss';
@import '../../base/media-queries.scss';
@import '../../base/mixins.scss';

.category-list {
  @include container();
  margin-bottom: $p-md;

  ul {
    margin-left: 10px;
    margin-right: 10px;

    li {
      font-family: $font-body;
      font-size: 12px;
      display: inline;
      padding-right: 15px;
      a {
        color: #262626;
        text-decoration: none;
        transition: color 0.2s cubic-bezier($bez-transition);

        &:hover,
        &:focus {
          color: $red;
        }
        &.active {
          color: $red;
        }
      }
    }
  }
}
