@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.cta-strip {
  background-color: $yellow;
  padding: $p-sm 0;

  &__inner {
    @include container(700px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media("below-sm") {
      flex-direction: column;
    }
  }

  &__label {
    @include h2();
    margin: 0 0 $p-sm;
    text-align: center;

    @include media("above-sm") {
      margin: 0 $p-md 0 0;
      text-align: left;
    }
  }

  .btn {
    flex-shrink: 0;
  }

}

