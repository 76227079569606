@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.hero {
  @include container();
  max-width: 740px;
  font-family: $font-body;
  margin-bottom: $p-md;
  text-align: center;

  &__header {
    @include h1-hero();
    @include stripe(inherit, 128px, 128px);
    border-color: $dark-blue;
  }

  &__title {
    margin-bottom: $p-md;
  }

  &__tag {
    font-size: 14px;
    line-height: 1.5;
    color: $black;
    margin-bottom: $p-sm;
  }

  &__author {
    font-size: $font-size-base;
    color: #000000;
    margin-top: $p-sm;
  }

  &__department {
    font-size: 12px;
    line-height: 1.33;
    color: $mid-grey;
    margin-top: $p-xs;
  }
}
