@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.service-intro-list {
  @include container();
  background-color: $white;
  margin-bottom: $p-xl;
  padding: $p-md;

  @include media("above-xs") {
    padding: $p-lg;
  }
}
