@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.pager {
  // Allow us to refer to the top selector using #{$root}.
  $root: &;

  @include clearfix();
  @include headingFont();
  display: flex;
  justify-content: center;
  margin: 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  margin: 20px 0 calc(#{$p-giant} / 1.333) 0;

  @include media("above-sm") {
    font-size: 22px;
  }

  &__pages {
    border-radius: 0;
    display: flex;
    padding-right: 5px;
  }

  &__ellipsis,
  &__current,
  &__first,
  &__last,
  &__next,
  &__previous,
  &__last {
    display: inline-block;
    list-style: none;
    min-width: 34px;

    a {
      color: $black;
      margin: 0;
      padding: 0;
      display: inline-block;
      text-decoration: none;
      line-height: 34px;

      &:hover,
      &:focus {
        color: $red;
      }
    }
  }

  // Hide ellipses on mobile to keep horizontal width usage minimal.
  @include media("below-sm") {
    &__ellipsis {
      display: none;
    }
  }

  &__current {
    border: none;
    color: $white;
    background-color: $black;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    width: 34px;
    height: 34px; 
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    order: 2;
  }

  &__ellipsis {
    order: 2;
    line-height: 34px;
  }

  &__previous,
  &__next {
    margin: 0 16px;

    a {
      transition: color 0.2s cubic-bezier($bez-transition);
    }
  }
  
  &__previous {
    order: 1; // Should appear as first link
  }

  &__next {
    order: 3; // Should appear as last link
  }

  &__single_control {
    width: inherit;
  }

  &__item {
    display: inline-block;
    list-style: none;
    min-width: 34px;
    order: 2;
    margin: 0 2px;

    @include media("above-sm") {
      margin: 0 5px;
    }

    a {
      border: none;
      border-radius: 50%;
      color: $black;
      margin: 0;
      padding: 0;
      display: inline-block;
      text-decoration: none;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      transition: background-color 0.2s cubic-bezier($bez-transition), color 0.2s cubic-bezier($bez-transition);
        
      &:hover,
      &:focus {
        color: $white;
        background-color: $red;
      }
    }
  }
}
