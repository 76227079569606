@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.software-logos {
  @include container();

  @include media("below-xs") {
    margin-bottom: $p-lg;
  }

  &__header {
    @include stripe(#babcbf, 196px, 149px);
    margin-bottom: $p-sm;
  }

  &__heading {
    @include h5();
    margin-bottom: calc(#{$p-xs} * 1.25);
    text-align: center;
  }

  &__list {
    @include unstyledList();
    text-align: center;

    @include media("above-xs") {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    > li {
      margin: $p-xs;
      img {
        min-width: 150px;
      }
    }
  }

  &__image {
    @include media("below-xs") {
      width: 50%;
    }
  }
}
