@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.bordered-text {
  padding: $p-sm 0;
  margin-bottom: $p-sm;

  @include media("above-xs") {
    border-left: solid 5px $blue;
    margin-left: $p-sm;
    padding: $p-xs $p-md;
  }

  @include media("below-xs") {
    border-top: solid 5px $blue;
  }
}
