@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";


@mixin blockquoteColours($triangleColor: $blue, $bgColor: $dark-blue) {
  /*
  @include bgTriangle($triangleColor, top-left);
  */
  background-color: $bgColor;
}

.blockquote {
  @include blockquoteColours();
  color: $white;
  margin: 0 0 $p-lg;
  padding: $p-xl 0;
  position: relative;
  text-align: center;

  &--alt {
    @include blockquoteColours($green, $light-green);
  }

  &__container {
    @include container(960px);
    position: relative;
    z-index: 1;
  }

  &__body {
    @include stripe($white);
    @include headingFont();
    font-size: 28px;
    line-height: 1.3;
    padding-top: $p-lg;
    position: relative;

    &::before {
      content: '\201C';
      @include headingFont();
      font-size: 180px;
      line-height: 0;
      position: absolute;
      top: 2.25rem;
      left: 0;
      right: 0;
      color: $red;
    }
  }

  &__footer {
    margin-top: $p-sm;
  }

  &__author {
    display: block;
    @include bodyText();
    font-size: 22px;
    font-style: normal;
    color: white;
    text-align: center;

    @include media("above-xs") {
      font-size: 17px;
    }
  }

  &__author-detail {
    font-size: 14px;
  }
}
