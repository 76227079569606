@mixin headingFont() {
  font-family: $font-heading;
  font-weight: normal;
}

@mixin h1() {
  @include headingFont();
  color: $black;
  font-size: 36px;
  line-height: 37px;
  text-align: center;
  @include media("above-sm") {
    font-size: 60px;
    line-height: 64px;
  }
}

@mixin h1-hero() {
  @include headingFont();
  font-size: 36px;
  line-height: 37px;
  text-align: center;
  @include media("above-sm") {
    font-size: 60px;
    line-height: 64px;
  }
}

@mixin h2() {
  @include headingFont();
  color: $black;
  font-size: 32px;
  line-height: 34px;
  text-align: left;
  @include media("above-sm") {
    font-size: 48px;
    line-height: 52px;
  }
}

@mixin h3() {
  font-family: $font-body-bold;
  color: $black;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: $p-sm;
  text-align: left;
  @include media("above-sm") {
    font-size: 36px;
  }
}

@mixin h4($color: $black) {
  font-family: $font-body-bold;
  color: $color;
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: $p-sm;
  text-align: left;
  @include media("above-sm") {
    font-size: 32px;
  }
}

@mixin h5($color: $black) {
  font-family: $font-body-bold;
  color: $color;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: $p-sm;
  text-align: left;

  @include media("above-sm") {
    font-size: 26px;
  }
}

@mixin h6() {
  font-family: $font-body-bold;
  color: $black;
  font-size: 17px;
  line-height: 1.2;
  margin-bottom: $p-xs;
  text-align: left;
  @include media("above-sm") {
    font-size: 20px;
  }
}

@mixin navText() {
  @include bodyTextBold();
  color: $black;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
}

@mixin bodyText($color: $black) {
  color: $color;
  font-family: $font-body;
  font-size: $mobile-font-size-base;
  line-height: 1.5;
  text-align: left;

  @include media("above-sm") {
    font-size: $font-size-base;
  }
}

@mixin bodyTextBold($color: $black) {
  color: $color;
  font-family: $font-body-bold;
  font-size: $mobile-font-size-base;
  line-height: 1.5;
  text-align: left;

  @include media("above-sm") {
    font-size: $font-size-base;
  }
}

@mixin smallText() {
  font-family: $font-body;
  font-size: 12px;
  line-height: 1.3;
}

@mixin bodyLink($color: $black, $hover-color: $red, $font-family: $font-body-bold) {
  color: $color;
  font-family: $font-family;
  font-weight: normal;
  transition: text-decoration 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover,
  &:focus {
    color: $black;
    text-decoration-color: $hover-color;
  }
}

@mixin bulletList() {
  margin-bottom: 40px;

  li {
    position: relative;
    padding-left: 35px;

    + li {
      margin-top: $p-xs;
    }

    @include media("above-xs") {
      padding-left: 30px;
    }

    &::before {
      content: '•';
      font-size: 40px;
      position: absolute;
      line-height: $mobile-font-size-base;
      left: 0;
      top: 7px;

      @include media("above-xs") {
        font-size: 30px;
        line-height: $font-size-base;
        top: 5px;
      }
    }
  }
}

@mixin numberList() {
  margin-bottom: 40px;

  li {
    counter-increment: step-counter;
    margin-bottom: $p-xs;
    position: relative;
    padding-left: 35px;

    @include media("above-xs") {
      padding-left: 30px;
    }

    &::before {
      content: counter(step-counter) '.';
      font-family: $font-body-bold;
      line-height: 1;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
}

@mixin inlineCode() {
  color: $dark-grey;
  font-size: 15px;
  font-weight: bold;
}

@mixin blockCode() {
  border-top: solid 4px $light-green;
  font-size: 13px;
  margin-bottom: $p-md;
}
