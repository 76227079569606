@import '../../base/settings.scss';
@import '../../base/media-queries.scss';
@import '../../base/mixins.scss';

.card {
  @include container();
  background: $light-blue;
  padding: $p-md;
  margin-bottom: $p-lg;
  position: relative;

  @include media('above-sm') {
    padding: $p-lg;
  }

  &--jumbo {
    @include media('above-sm') {
      padding: $p-lg $p-xl;
    }
  }

  &--offset {
    @include media('above-md') {
      margin-top: calc((#{$p-giant} + 50px) * -1);
    }
  }

  &__image {
    margin: calc(#{$p-md} * -1);
    margin-bottom: $p-sm;

    @include media('above-sm') {
      margin: calc(#{$p-lg} * -1);
      margin-bottom: $p-md;
    }
  }

  &__heading {
    text-align: center;
  }

  li a,
  p a {
    @include bodyLink();
  }

  .list--bullet:last-of-type,
  p:last-of-type {
    margin-bottom: 0;
  }

  &__button {
    margin-top: $p-md;
    text-align: center;
  }
}
