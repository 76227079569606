@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

$timeline-bar-height: 27px;
$timeline-bar-width: 6px;
$space-between-item: 6px;

.timeline {
  @include container();
  font-size: 16px;

  @include media("below-xs") {
    text-align: center;
  }

  &__heading {
    @include media("below-xs") {
      text-align: center;
    }
  }
  
  &__list {
    padding-top: calc(#{$timeline-bar-height} + #{$p-sm});
    padding-left: calc(#{$p-xs} / 2);
    margin-bottom: $p-xl;
    position: relative;

    &::before,
    &::after {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      background-color: $red;
      border-radius: 100%;
      left: -1px;
      z-index: 2;

      @include media("below-xs") {
        left: calc(50% - 4px);
        right: calc(50% - 4px);
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: -20px;
    }

  }

  &__item {
    position: relative;
    padding-bottom: calc(#{$timeline-bar-height} + #{$space-between-item});
    margin-bottom: $space-between-item;

    &:first-of-type {
      margin-top: $space-between-item;

      &::before {
        content: '';
        width: $timeline-bar-width;
        height: $timeline-bar-height;
        position: absolute;
        left: 1px;
        top: calc((#{$timeline-bar-height} + #{$space-between-item}) * -1);
        background-color: #cfc6cc;

        @include media("below-xs") {
          left: 50%;
          right: 50%;
        }
      }
    }

    &::after {
      content: '';
      width: $timeline-bar-width;
      height: $timeline-bar-height;
      position: absolute;
      bottom: 0;
      left: 1px;
      background-color: #cfc6cc;

      @include media("below-xs") {
        left: 50%;
        right: 50%;
      }
    }

    a {
      @include bodyLink();
    }
  }

  &__info {
    font-size: 14px;
  }
}
