@font-face {
  font-family: 'DuplicateIonic-Regular'; src:url('./fonts/Duplicate/DuplicateIonic-Regular.otf'); //src:url('./fonts/Duplicate/DuplicateIonic-Regular.otf#iefix') format("embedded-opentype");
  font-display: swap;
}
/*
@font-face {
  font-family: 'CircularStd-Bold'; src:url('./fonts/Circular/CircularStd-Bold.otf'); //src:url('./fonts/Circular/CircularStd-Bold.otf#iefix') format("embedded-opentype");
  font-display: swap;
}
*/

@font-face {
  font-family: 'MaisonNeue-Book'; src:url('./fonts/MaisonNeue/MaisonNeue-Book.ttf'); //src:url('./fonts/MaisonNeue/MaisonNeue-Book.ttf#iefix') format("embedded-opentype");
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue-Bold'; src:url('./fonts/MaisonNeue/MaisonNeue-Bold.ttf'); //src:url('./fonts/MaisonNeue/MaisonNeue-Bold.ttf#iefix') format("embedded-opentype");
  font-display: swap;
}

body {
  @include bodyText();
  background-color: $white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  @include h1();
}

h2,
.h2 {
  @include h2();
}

h3,
.h3 {
  @include h3();
}

h4,
.h4 {
  @include h4();
}

h5,
.h5 {
  @include h5();
}

h6,
.h6 {
  @include h6();
}

p strong,
ul strong,
ol strong,
label strong,
p b,
ul b,
ol b,
label b {
  font-family: $font-body-bold;
  font-weight: normal;
}

.noscroll {
  overflow: hidden;
}

.container {
  @include container();
}

// @todo: move?
.xs-visible {
  @include media("above-xs") {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.list--bullet {
  @include unstyledList();
  @include bulletList();
}
