@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.hero-full {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -52px;
  background-color: $black;
  @include media("above-sm") {
    flex-direction: row;
    padding-bottom: 50%;
  }
  &__media {
    display: flex;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50vh;
    @include media("above-sm") {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-bottom: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.45);
      }
    }
  }
  &__container {
    width: 100%;
    align-self: center;
    padding: 30px 20px;
    background-color: $black;
    z-index: 1;
    @include media("above-sm") {
      @include container(1440px);
      padding: 0;
      background-color: transparent;
    }
    &--mobile {
      display: block;
      @include media("above-sm") {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @include media("above-sm") {
        display: block;
      }
    }
  }

  &__column {
    @include media("above-md") {
      @include cols(6, 12);
      @include cols-push(1, 12);
    }
  }
  &__tagline {
    color: white;
    p {
      margin-bottom: 10px;
    }
  }
  &__title {
    border-top: 4px solid white;
    h1 {
      font-size: 36px;
      text-align: left;
      color: $white;
      margin-bottom: 20px;
      @include media("above-sm") {
        font-size: 60px;
      }
    }
  }
  &__tags {
    ul {
      display: flex;
      flex-flow: row wrap;
      padding: 0;
      margin: -5px;
    }
    li {
      color: $white;
      font-family: $font-body-bold;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      list-style: none;
      padding: 10px;
      border-radius: 1px;
      border: 2px solid $white;
      margin: 5px;
    }
  }
}
