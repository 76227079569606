@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.service-summary-list {
  margin-bottom: $p-md;

  @include media("above-xs") {
    margin-bottom: $p-xl;
  }

  .service-summary {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s cubic-bezier($bez-transition), transform 0.8s cubic-bezier($bez-transition);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
