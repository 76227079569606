@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";


.service-summary {
  @include container();
  background-color: $white;

  + .service-summary {
    margin-top: $p-xl;
  }

  &__header {
    background-color: $light-red;
    padding: $p-lg $p-md;
    position: relative;
    text-align: center;

    // Types of card
    .blue & {
      background-color: $blue;
    }

    .green & {
      background-color: $light-green;
    }

    .black & {
      background-color: #light-yellow;
    }

    &__inner {
      position: relative;
      z-index: 1;
    }

    @include media("above-xs") {
      padding: $p-xl $p-giant;
    }
  }

  &__icon {
    box-shadow: 4px 4px 0 0 rgba(3, 4, 3, 0.1);

    @include media("below-xs") {
      margin-bottom: $p-xs;
    }
  }

  &__heading {
    margin-bottom: 0;
    text-align: center;
  }

  p a {
    @include bodyLink();
  }

  &__body {
    padding: $p-sm;

    @include media("above-xs") {
      padding: $p-sm;
    }
  }

  &__btn {
    margin-top: $p-xs;
  }
}
