@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.article-cards {
  @include container();
  margin-bottom: $p-md;

  .article-card,
  .labs-card {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.2s cubic-bezier($bez-transition), transform 0.2s cubic-bezier($bez-transition), box-shadow 0.2s cubic-bezier($bez-transition);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .article-card {
  
    &:hover,
    &:focus {
      box-shadow: 4px 4px 0 rgba(3, 4, 3, 0.1);
      transform: translateY(-5px);
    }

    &:active {
      transition-delay: 0s !important;
      box-shadow: 0 0 0 rgba(3, 4, 3, 0.1);
    }
  }

  @include media("above-xs") {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .labs-card {
    @include media("above-xs") {
      width: calc(50% - #{$p-xs});
    }
  }
}
