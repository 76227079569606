@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

@keyframes animateUp {
  from {transform: translateY(200px)}
  to {transform: translateY(0)}
}

.image-text {
  background-color: $dark-blue;
  padding: 40px 0 50px;
  margin-bottom: 65px;
  @include media("above-sm") {
    padding: 40px 0 0;
  }
  @include media("above-md") {
    padding: 100px 0 0;
  }
  &__container {
    @include container(1440px);
    display: flex;
    flex-direction: column;
    @include media("above-md") {
      flex-direction: row;
    }
  }
  &__media-wrap {
    position: relative;
    min-height: 525px;
    border: 4px solid $white;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom: 0;
    overflow: hidden;
    animation: animateUp 0.75s;
    animation-timing-function: cubic-bezier(.32,.07,.5,.98);
    animation-fill-mode: forwards;
    margin: 0 auto;
    width: 280px;
    @include media("above-xs") {
      min-width: 335px;
    }
    @include media("above-sm") {
      flex: initial;
      min-width: 400px;
      min-height: 540px;
      margin: 0 auto ;

    }
    @include media("above-md") {
      @include cols-push(1, 12);
      min-height: 760px;
      margin-top: -210px;
      transform: translateY(200px);
    }
  }
  &__media {
    position: relative;
    height: 100%;
    video {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      max-width: 274px;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1000;
      overflow: hidden;
      margin-top: -2px;
      @include media("above-sm") {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
        min-height: 100%;
        max-width: initial;
      }
    }
  }
  &__content-wrap {
    margin: 0 auto;
    @include media("above-md") {
      @include cols(4, 12);
      @include cols-push(2, 12);
    }
  }
  &__tagline {
    color: $white;
    margin-top: 30px;
    font-family: $font-body-bold;
    @include media("above-sm") {
      margin-top: 0;
    }
  }
  &__title {
    margin-top: 20px;
    h2 {
      color: $white;
    }
  }
  &__text {
    color: $white;
    margin-top: 20px;
  }
}
