@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.hr {
  display: block;
  border-top: solid 6px $dark-blue;
  margin-bottom: $p-md;
  margin-left: 0;
  margin-right: 0;
  width: 149px;

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  @include media("above-sm") {
    width: 196px;
  }
}
