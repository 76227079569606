@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

$icon-height: 74px;
$icon-width: 74px;

.media-hero {
  /*
  @include bgTriangle(rgba($green, 0.85), top-left);
  */
  margin-bottom: $p-lg;
  padding: calc(#{$p-giant} + #{$p-sm}) 0;
  position: relative;
  background-size: cover;
  background-position: center;

  @include media("above-sm") {
    margin-bottom: $p-xl;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.65);
  }

  &--alt {
    margin-bottom: calc(#{$p-md} + (#{$icon-height} / 2));

    @include media("above-sm") {
      margin-bottom: calc(#{$p-lg} + (#{$icon-height} / 2));
    }

    &::before {
      background-color: transparent;
    }
  }

  .header + & {
    margin-top: -52px;
  }

  &__container {
    @include container(640px);
    position: relative;
    z-index: 1;
  }

  &__heading {
    color: $white;
  }

  &__text {
    @include h1-hero();
    @include stripe($white, 148px, 148px);
    font-size: 40px;
    color: $white;
    text-shadow: 6.2px 7.9px 133px rgba(3, 4, 3, 0.35);

    @include media("above-xs") {
      font-size: 48px;
    }

    p {
      margin-bottom: $p-md;
    }
  }

  &__icon {
    width: $icon-width;
    height: $icon-height;
    margin: 0 auto;
    position: absolute;
    z-index: 2;
    bottom: calc(-#{$icon-height} / 2);
    left: 0;
    right: 0;
    box-shadow: 2px 2px 0px 1px rgba(0,0,0,0.1);
  }
}
