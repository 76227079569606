@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.two-col-text {
  @include container(700px);
  margin-bottom: $p-lg;

  @include media("above-xs") {
    display: flex;
    justify-content: space-between;
    margin-bottom: $p-md;
  }

  &__col {
    @include media("above-xs") {
      width: calc(50% - #{$p-sm});
    }
    @include media("above-sm") {
      width: calc(50% - #{$p-md});
    }
  }

  &__body {
    @include bodyText();
  }

  a {
    @include bodyLink();
  }
}
