//   Buttons
// ------------------------------------------------------------------

$btn-mobile-padding: 12px 28px;
$btn-padding: 10px 24px;

@mixin btn($color: $black, $bgColor: transparent, $borderColor: $black) {
  @include bodyTextBold() ;
  background-color: $bgColor;
  border: solid 4px $borderColor;
  color: $color;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: $btn-mobile-padding;
  text-decoration: none;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), border-color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  @include media("above-xs") {
    border: solid 3px $borderColor;
    font-size: $font-size-base;
    padding: $btn-padding;
  }
}

@mixin btnHover($hoverColor: $white, $hoverBgColor: $black, $hoverBorderColor: $black) {
  &:hover,
  &:focus {
    background-color: $hoverBgColor;
    border-color: $hoverBorderColor;
    color: $hoverColor;
    box-shadow: 4px 4px 0px rgba(3,4,3,.2);
    transform: translateY(-4px);
  }

  &:active {
    transform: translateY(0px);
    box-shadow: 0px 0px 0px rgba(3,4,3,.15);
  }
}

@mixin btnArrow($arrow-color: $black, $hover-arrow-color: $white) {
  background-image: svg-load("../buttonLink/btnArrow.svg", fill=$arrow-color);
  background-position: right 32px center;
  background-repeat: no-repeat;
  background-size: 31px 12px;
  padding-right: 80px;

  @include media("above-xs") {
    background-position: right 24px center;
    background-size: 20px 9px;
    padding-right: 58px;
  }

  &:hover,
  &:focus {
    background-image: svg-load("../buttonLink/btnArrow.svg", fill=$hover-arrow-color);
  }
}
