@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.two-col-img {
  margin-bottom: $p-lg;

  @include media("above-sm") {
    display: flex;
  }

  @include media("below-sm") {
    margin-left: $p-sm;
    margin-right: $p-sm;
  }

  &__col {
    @include media("above-sm") {
      text-align: right;
      width: 50%;
    }

    &:last-child {
      text-align: left;

      @include media("above-sm") {
        .two-col-img__caption {
          order: 1;
        }
        img {
          order: 2;
        }
      }
      @include media("below-sm") {
        margin-top: $p-sm;
      }
    }
  }

  &__figure {
    display: flex;
    flex-direction: column;
  }

  &__caption {
    padding: $p-sm;
    background-color: $white;
    color: $mid-grey;
    font-size: 12px;

    @include media("above-xs") {
      font-size: 16px;
      padding: $p-sm $p-md;
    }
  }


}
