//  Site variables
// ------------------------------------------------------------------

$black: #262626;
$white: #ffffff;
$dark-grey: #404348;
$mid-grey: #53585e;
$light-grey: #edeee9;
$green: #009CA6;
$light-green: #BCE8EB;
$light-red: #FFDBDA;
$red: #ff0046;
$blue: #DADFE6;
$light-blue: #E9EDF4;
$baby-blue: #bcd5ec;
$dark-blue: #00425E;
$beige: #cfc6cc;
$yellow: #FFBB00;
$light-yellow: #FFF7CC;

$font-heading: 'DuplicateIonic-Regular', Helvetica, Arial;
$font-body: 'MaisonNeue-Book', Helvetica, Arial;
$font-body-bold: 'MaisonNeue-Bold', Helvetica, Arial;
$font-code: Menlo, Consolas, Monaco, monospace;
$font-size-base: 18px;
$mobile-font-size-base: 17px;

$bez-transition: 0.4,0,0.2,1;

$container-width: 600px;

// @todo: spacing/padding mixin
// so we can have both desktop and mobile values?
$p-xs: 10px;
$p-sm: 20px;
$p-md: 32px;
$p-lg: 45px;
$p-xl: 65px;
$p-giant: 100px;

// Mobile padding
$mb-p-xs: 5px;
$mb-p-sm: 10px;
$mb-p-md: 15px;
$mb-p-lg: 20px;
$mb-p-xl: 30px;
$mb-p-giant: 50px;
