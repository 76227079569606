.chevron-link {
  color: $black;
  font-family: $font-body-bold;
  padding-right: 15px;
  position: relative;
  text-decoration: none;
  transition: color 0.25s ease;

  &:hover,
  &:focus {
    color: $red;

    &:after {
      border-color: transparent transparent transparent $red;
    }
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent $black;
    position: absolute;
    right: 0;
    top: 3px;
    transition: border-color 0.25s ease;
  }
}
