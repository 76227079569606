@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.btn {
  @include btn();
  @include btnHover();
}

.btn--invert {
  @include btn($white, transparent, $white);
  @include btnHover($black, $white, $white);
}

.btn--standout {
  @include btn($white, $red, $red);
  @include btnArrow($white, $white);
  @include btnHover($white, $green, $green);

  &--wide {
    padding-right: 24px;
    width: 100%;
  }
}

.btn--arrow {
  @include btnArrow($black, $white);
}
