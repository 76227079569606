@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.article-card {
  background-color: $blue;
  margin-bottom: $p-sm;
  text-decoration: none;

  > a {
    color: $black;
    display: block;
    text-decoration: none;
  }

  @include media("above-xs") {
    width: calc(50% - #{$p-xs});
  }

  &:hover,
  &:focus {
    .article-card__heading {
      color: $red;
    }
  }

  &__body {
    padding: $p-sm;
  }

  &__date {
    color: $black;
    display: block;
    font-size: 13px;
    margin-bottom: $p-xs;
  }

  &__heading {
    @include h5();
    margin-bottom: $p-xs;
    transition: color 0.25s ease;
  }

  &__summary {
    color: $black;
  }

  &__author {
    color: $black;
    display: block;
    line-height: 1;
  }

  &__author-role {
    color: rgba($black, 0.7);
    font-size: 13px;
  }
}
