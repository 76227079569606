@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.callout {
  @include container();
  background-color: $white;
  margin-bottom: $p-md;
  padding: $p-md;

  @include media("above-xs") {
    padding: $p-sm $p-md;
  }

  .callout__body {
    @include clearfix();
    font-family: $font-body;
    font-size: 18px;
    line-height: 1.5;
    padding: $p-sm 0 0;

    @include media("above-xs") {
      border-left: solid 6px $blue;
      padding: $p-xs 0 $p-xs $p-sm;
    }

    @include media("below-xs") {
      border-top: solid 6px $blue
    }

    p {
      margin-bottom: 0;
    }
  }

  dl {
    @include clearfix();
    font-size: 18px;
    margin-bottom: calc(#{$p-sm} * -1);

    @include media("above-xs") {
      margin-bottom: calc(#{$p-xs} * -1);
    }
  }

  dt,
  dd {
    display: block;

    @include media("above-xs") {
      float: left;
      margin-bottom: $p-xs;
    }
  }

  dt {
    @include media("above-xs") {
      clear: left;
      margin-right: $p-xs;
    }
    font-family: $font-body-bold;
  }

  dd {
    color: $mid-grey;

    @include media("below-xs") {
      margin-bottom: $p-sm;
    }
  }

  &--alt {
    .callout__body {
      border-color: $green;
    }
  }
}
