@import "./mixins.type.scss";
@import "./mixins.button.scss";

@mixin container($max-width: $container-width) {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  width: calc(100% - (#{$p-sm} * 2));
}

@mixin unstyledList() {
  list-style: none;
}

@mixin socialIconLink($icon, $fill: $mid-grey, $hover-fill: $green) {
  background-image: svg-load("./#{$icon}.svg", fill=$fill);

  &:hover,
  &:focus {
    background-image: svg-load("./#{$icon}.svg", fill=$hover-fill);
  }
}

@mixin bgTriangle($bg-color: $white, $type: top-left ) {
  &::after {
    content: '';
    height: 100%;
    width: 100%;
    background-image: svg-load("../triangle/#{$type}.svg", fill=$bg-color);
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

@mixin stripe($color: $green, $width: 196px, $mobileWidth: 149px) {
  &::after {
    content: '';
    display: block;
    border-top: solid 6px;
    border-color: $color;
    margin-left: auto;
    margin-right: auto;
    width: $mobileWidth;

    @include media("above-sm") {
      width: $width;
    }
  }
}

@mixin cols($n, $basis: 12) {
  width: (100% / $basis) * $n;
  max-width: (100% / $basis) * $n;
  flex-basis: (100% / $basis) * $n;
}

@mixin cols-push($n, $basis: 12) {
  margin-left: (100% / $basis) * $n;
}

@mixin clearfix() {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin hideText {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
