@import '../../base/settings.scss';
@import '../../base/media-queries.scss';
@import '../../base/mixins.scss';

$headerHeight: 89px;
$headerMobileHeight: 53px;
$numberOfLinks: 8;
$header-xs: 450px;
$text-color-body: #151221;

.header {
  width: 100%;
  height: $headerHeight;
  background-color: $white;
  display: flex;
  align-items: center;
  margin-bottom: 52px;
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
  }
  
  &__nav {
      width: 100%;
      background-color: $white;
    &__logo-link {
      img {
        display: block;
        width: 155px;
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    &__item {
      display: inline-block;
    }

    &__link {
      @include navText();
      text-decoration: none;
      position: relative;
      padding-bottom: 10px;
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $text-color-body;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }
      &:hover, &:focus {
        text-decoration: none;
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
      &--active {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}

