@import "../../base/settings.scss";
@import "../../base/media-queries.scss";
@import "../../base/mixins.scss";

.case-study-list {
  position: relative;
  margin-bottom: $p-xl;

  @include media("above-xs") {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .case-study-card {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.8s cubic-bezier($bez-transition), transform 0.8s cubic-bezier($bez-transition);

    &.is-visible {
      opacity: 1;
      transform: translateY(0);
    }

    @include media("above-xs") {
      margin-bottom: 0;
    }

    &:nth-child(2) {
      margin-top: 25%;

      @include media("below-xs") {
        margin-top: 0 !important;
      }
    }
  }
}
